// users 
export const SIGNIN = "SIGNIN"

export const GET_ALL_FILES = "GET_ALL_FILES"
export const GET_A_FOLDER = "GET_A_FOLDER"
export const GET_A_FILE = "GET_A_FILE"
export const CHANGE_DIR = "CHANGE_DIR"
export const CREATE_FOLDER = "CREATE_FOLDER"


/**
 * UI constants 
 */

export const TOGGLE_LOADING = "TOGGLE_LOADING"